import React, { Component, useState } from 'react';
import styles from "../styles/Home.module.css";
import ImgBot from "../imgs/bot.webp";
import ImgBot4 from "../imgs/bot_4.png";
import ImgUser from "../imgs/usericon.png";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";


interface MessageProps {
    id: number;
    content: string;
    role: string;
    model: string;
}

interface MessageComponentProps {
    index: number; 
    message: MessageProps;
    messagesLength: number;
    loading: boolean;
    onLike: (id: number) => Promise<boolean>;
    onDislike: (id: number) => Promise<boolean>;
}


const MessageComponent: React.FC<MessageComponentProps> = ({ index, message, messagesLength, loading, onLike, onDislike }) => {

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [likeSucceeded, setIsLikeSucceeded] = useState(false);
    const [dislikeSucceeded, setIsDislikeSucceeded] = useState(false);

    const handleLikeClick = async (id: number) => {
        setIsButtonDisabled(true); // Disable the button when clicked
        const resp: boolean  = await onLike(id); // Call the callback function with the clicked button
        setIsLikeSucceeded(resp);
    };

    const handleDislikeClick = async (id: number) => {
        setIsButtonDisabled(true); // Disable the button when clicked
        const resp: boolean  = await onDislike(id); // Call the callback function with the clicked button
        setIsDislikeSucceeded(resp);
    };

    return (
        // The latest message sent by the user will be animated while waiting for a response
        <>
        <div
            className={
            message.role === "user" &&
            loading &&
            index === messagesLength - 1
                ? styles.usermessagewaiting
                : message.role === "assistant"
                ? styles.apimessage
                : styles.usermessage
            }
        >
            {/* Display the correct icon depending on the message type */}
            {message.role === "assistant" ? (
            <img
                src={message.model === "gpt-4" || message.model === "gpt-4-turbo" ? ImgBot4 : ImgBot}
                alt="AI"
                width="30"
                height="30"
                className={styles.boticon}
            />
            ) : (
            <img
                src={ImgUser}
                alt="Me"
                width="30"
                height="30"
                className={styles.usericon}
            />
            )}
            <div className={styles.markdownanswer}>
            {/* Messages are being rendered in Markdown format */}
            <ReactMarkdown linkTarget={"_blank"} remarkPlugins={[remarkBreaks]}>
                {message.content}
            </ReactMarkdown>
            </div>
        </div>
        {message.id !== -1 ?
            <div className={styles.likemessage}>
                <div className={styles.likefield}>
                <Button className={likeSucceeded ? styles.btnok : styles.btn} onClick={() => handleLikeClick(message.id)} disabled={isButtonDisabled}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                </Button>
                <Button className={dislikeSucceeded ? styles.btnok : styles.btn} onClick={() => handleDislikeClick(message.id)} disabled={isButtonDisabled}>
                    <FontAwesomeIcon icon={faThumbsDown} />
                </Button>
                </div>
            </div>
        : null}
        </>
    );
}
export default MessageComponent;