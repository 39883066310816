import { useState, useRef, useEffect, FormEvent, KeyboardEvent, ChangeEvent} from "react";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./styles/Home.module.css";
import MessageContent from "./components/message"


const App = () => {
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('gpt-4o');
  const [invalidInput, setInvalidInput] = useState(false);

  const [messages, setMessages] = useState([
    { id: -1, role: "assistant", content: "Welcome! I'm here to assist you by searching Luxonis documentation and past forum discussions for answers. How can I help you today?", model: selectedOption},
  ]);

  const messageListRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Auto scroll chat to bottom
  useEffect(() => {
    if (messageListRef.current) {
      const messageList = messageListRef.current;
      messageList.scrollTop = messageList.scrollHeight;
    }
  }, [messages]);


  // Focus on input field
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  // Handle errors
  const handleError = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: -1,
        role: "assistant",
        content: "Oops! There seems to be an error. Please try again.",
        model: selectedOption
      },
    ]);
    setLoading(false);
    setUserInput("");
  };

  const handleLike = async (id: number): Promise<boolean> => {
    return await logLike(id, 1);
  };

  const handleDislike = async (id: number): Promise<boolean> => {
    return await logLike(id, -1);
  };

  const logLike = async (id: number, value: number): Promise<boolean> => {
    try {
      const response = await fetch("/api/like", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entry_id: id,
          value: value,
        }),
      });
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error adding like:', error);
      return false;
    }
  }

  

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (userInput.trim() === "") {
      setInvalidInput(true);
      return;
    }
    if (userInput.length < 10) {
      setInvalidInput(true);
      return;
    }

    setInvalidInput(false);
    setLoading(true);
    const context = [...messages, { id: -1, role: "user", content: userInput, model: selectedOption }];
    let filteredContext = context.map(({ role, content }) => ({ role, content }));
    filteredContext = filteredContext.slice(1);
    filteredContext = filteredContext.slice(-3);
    setMessages(context);

    // Send chat history to API
    try {
      const response = await fetch("/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: filteredContext,
            model: selectedOption,
            rerank: true
        }),
      });
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Response is not in JSON format');
      }

      console.log(JSON.stringify({ query: userInput }));
      const data = await response.json();
      console.log(data);

      if (!data) {
        handleError();
        return;
      }
      setUserInput("");
      console.log(data);
      //const fixed = data.content.replace(/\n/gi, '\n &nbsp;');
      //const fixed = replaceTextWithLinks(data.content)
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: data.id, role: "assistant", content: data.content, model: data.model },
      ]);
      setLoading(false);

    } catch {
      // Reset user input
      setUserInput("");
      handleError();
      return;
    }
    
  };

  // Prevent blank submissions and allow for multiline input
  const handleEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && userInput) {
      if (!e.shiftKey && userInput) {
        handleSubmit(e);
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
    }
  };


  return (
    <>
      <div>
        <link rel="apple-touch-icon" href="/openai.png" />
        <title>Luxonis Chat</title>
        <meta name="description" content="Luxonis Chat Interface" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" />
      </div>
      <div className={styles.topnav}>
        <div className={styles.navlogo}>
          <a href="/">Luxonis Chat</a>
        </div>
        <div className={styles.navlinks}>
          <a
            href="https://docs.luxonis.com"
            target="_blank"
          >
            Docs
          </a>
          <a
            href="https://discuss.luxonis.com"
            target="_blank"
          >
            Forum
          </a>
        </div>
      </div>
      <main className={styles.main}>
        <div className={styles.cloud}>
          <div ref={messageListRef} className={styles.messagelist}>
            {messages.map((message, index) => {
              return (
                <MessageContent
                  key={index}
                  index={index}
                  message={message}
                  messagesLength={messages.length}
                  loading={loading}
                  onLike={() => handleLike(message.id)}
                  onDislike={() => handleDislike(message.id)}
                />
              )
            })}
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.cloudform}>
            <form onSubmit={handleSubmit}>
              <textarea
                disabled={loading}
                onKeyDown={handleEnter}
                ref={textAreaRef}
                autoFocus={false}
                rows={1}
                maxLength={512}
                
                id="userInput"
                name="userInput"
                placeholder={
                  loading ? "Waiting for response..." : "Type your question..."
                }
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                className={styles.textarea}
                style={invalidInput ? {border: "1px solid #4e38ed", borderRadius: "0.5rem"} : {border: "1px solid #ccc", borderRadius: "0.5rem"}}
              />
              <button
                type="submit"
                disabled={loading}
                className={styles.generatebutton}
              >
                {loading ? (
                  <div className={styles.loadingwheel}>
                    <CircularProgress color="inherit" size={20} />{" "}
                  </div>
                ) : (
                  // Send icon SVG in input field
                  <svg
                    viewBox="0 0 20 20"
                    className={styles.svgicon}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                )}
              </button>
            </form>
          </div>
          <div className={styles.footer}>
            <p>
              Information you are viewing might be outdated or the model might hallucinate. Please check important information.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
export default App;
